<template>
  <section>
    <p>
      Certificación / {{ proceso.id ? proceso?.codigo_proceso : "" }} /
      {{ fondoC?.id ? fondoC?.cifrado : "" }}
    </p>

    <span class="text-h5 secondary--text">{{ proceso?.nombre_proceso }}</span>

    <v-row no-gutters class="mt-5 mb-12">
      <v-col cols="12" sm="12" md="6" class="mb-12">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo primary--text"
              >
                <p>Cifrado</p>
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo mb-2">
                <p>{{ fondoC?.cifrado }}</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row v-for="fondoCifrado in fondoCifrados" :key="fondoCifrado.Fondo?.CatalogosCifrados?.ConstruccionesCifrados?.id
">
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo"
              >
                <p>{{ fondoCifrado.tipo }}</p>
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo mb-2">
                <p>{{ fondoCifrado.nombre }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6" class="mb-12">
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-file-input
              outlined
              label="Certificación de fondos"
              prepend-icon=""
              append-icon="mdi-paperclip"
              accept=".pdf"
              v-model="documento"
              @input="$v.documento.$touch()"
              @blur="$v.documento.$touch()"
              :error-messages="documentoError"
            ></v-file-input>
            <v-btn color="secondary" dark @click="descargarDocumento" max-width="40%" class="ml-2" v-if="this.rutaDocumento">
              <v-icon>mdi-download</v-icon>Descargar archivo
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              no-data-text="No hay datos"
              hide-default-footer
              :items="unidades"
            >
              <template #[`item.monto`]="{ item }">
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  }).format(item.monto)
                }}
              </template>
              <template #[`item.checkbox`]="{ item }">
                <v-checkbox v-model="id_unidades" :value="item.id_financiamiento_unidad" @change="seleccionar(item)"></v-checkbox>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-12">
      <v-btn
        outlined
        color="secondary"
        class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
        @click="regresar()"
      >
        Volver
      </v-btn>
      <v-btn
        color="secondary"
        class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
        @click="actualizar()"
      >
        {{ fondoC?.estado === 'Pendiente' ? 'Certificar' : 'Actualizar' }}
      </v-btn>
    </v-row>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  data: () => ({
    headers: [
      {
        value: "checkbox",
        align: "center",
      },
      {
        text: "Unidad",
        value: "nombre",
        align: "start",
      },
      {
        text: "Monto ($)",
        value: "monto",
        align: "center",
      },
    ],
    unidades: [],
    documento: null,
    id_fondo: null,
    fondoCifrados: [],
    proceso: {},
    fondoC: {},
    id_unidades:[],
    id_fondos_procesos_certificado:null,
    rutaDocumento: null,
  }),
  validations:{
    documento: { required }
  },
  computed: {
    ...mapState("fondoCertificado", ["fondo"]),
    documentoError(){
        const errors = []
        if (!this.$v.documento.$dirty) return errors;
        !this.$v.documento.required &&
            errors.push("Debe de seleccionar un documento");
        return errors;
    }
  },
  methods: {
    ...mapMutations("fondoCertificado", ["setFondo"]),
    async getCertificacionFondo() {
      this.id_fondo = Number(this.$route.params.idCifrado);
      const { data, status } =
        await this.services.CertificacionFondo.getCertificacionFondo(
          this.id_fondo
        );
      if (status === 200) {
        this.rutaDocumento = data.certificado;
        this.fondoCifrados = data.construccion;
      }
    },
    regresar() {
      this.$router.back();
    },
    async getUnidadFondo() {
      const { data, status } =
        await this.services.CertificacionFondo.getCertificacionFondoUnidad(
          this.id_fondo
        );
      if (status == 200) {
        this.unidades = data;
        this.unidades.forEach((u) => {
          if (u.certificado) this.id_unidades.push(u.id_financiamiento_unidad)
        });
      }
    },
    seleccionar(item){
       if(item.checkbox === true){
           this.id_unidades.push(item.id_financiamiento_unidad)
       } else if(item.checkbox === false){
           this.id_unidades.pop()
       }
    },
    async actualizar(){
        this.$v.$touch()
        if(this.id_unidades === []){
            this.temporalAlert({
                show:true,
                type:"error",
                message:"Debe de seleccionar una unidad"
            })
        }
        if(!this.$v.$invalid){

            this.id_fondos_procesos_certificado = Number(this.$route.params.idCifrado);
            let formData = new FormData()
            formData.append("documento", this.documento)
            formData.append("unidades", JSON.stringify(this.id_unidades))
            
            const { status } = await this.services.CertificacionFondo.putCertificacion(
                this.id_fondos_procesos_certificado,
                formData
            ).catch(() => {

            })
            if(status == 204){
                this.temporalAlert({
                    show:true,
                    type:"success",
                    message:"La certificación fue un éxito"
                })
                this.$router.back()
            }


        }
    },
    async descargarDocumento() {

      const response = await this.services.Sanciones.descargarArchivo({
        ruta: this.rutaDocumento,
        disk: "procesos",
      }).catch(() => {

      });
      if (response.status == 200) {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.rutaDocumento;
        link.click();

      }
    },
  },
  async created() {

    setTimeout(() => {
      this.getCertificacionFondo();
      this.getUnidadFondo();

    }, 1000);
    if (this.$route.params.idProceso && this.$route.params.idCifrado) {
      const { data, status } = await this.services.PacProcesos.getProceso(
        Number(this.$route.params.idProceso)
      );
      if (status == 200) {
        this.proceso.codigo_proceso = data.codigo_proceso;
        this.proceso.nombre_proceso = data.nombre_proceso;
        this.proceso.id = data.id;
      }
      this.fondoC.cifrado = this.fondo.cifrado;
      this.fondoC.id = this.fondo.id;
      this.fondoC.estado = this.fondo.estado
    }
  },
  beforeDestroy() {
    this.setFondo({});
  },
};
</script>

<style scoped lang="scss">
.caracteristica-producto-titulo,
.caracteristica-producto-subtitulo {
  padding: 0px 12px 0px 12px;
  margin: 0px;
  p {
    margin: 0px 0px 5px 0px;
  }
}
.caracteristica-producto-titulo p {
  color: #697185;
}
</style>
